import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"
import { Link, graphql } from "gatsby"
import debraimage from "../images/profile/deb.png"
import zachimage from "../images/profile/zach.png"



export default function Blog({ data }) {  
  

    return (
        <Layout>
            <Seo title="Blog" />
            <HeroMini title="Blog"/>
            <main className="section has-text-black has-background-light">
                <div className="container">
                    <div className="columns content">
                        <div className="column has-text-black">
                            {data.allMarkdownRemark.edges.map(({ node }) => (
                            <div key={node.id} className="card">
                            <div>
                                <Link className="blogpost" to={'/blog'+node.fields.slug}>
                                <h3 style={{backgroundColor:"#009980"}} 
                                className="pt-3 card-header has-text-white p-3">
                                    {node.frontmatter.title}
                                </h3>
                                <div className="mb-4 pl-3" style={{display:"flex", paddingTop:"10px"}}>
                                  <figure style={{marginBottom:0}}>
                                    {node.frontmatter.author === "Debra Denholm" && <img style={{borderRadius:"50%", maxWidth:"80px"}}  className="is-rounded" alt={node.frontmatter.author} src={debraimage}/>}
                                    {node.frontmatter.author === "Zach Denholm" && <img style={{borderRadius:"50%", maxWidth:"80px"}}  className="is-rounded" alt={node.frontmatter.author} src={zachimage}/>}
                                  </figure>
                                <div>
                                  <span className="is-size-6">By {node.frontmatter.author}</span>
                                  <p>{node.frontmatter.date}</p>
                                </div>
                                </div>

                                
                                <p className="p-3">{node.excerpt}</p>
                                </Link><hr/>
                            </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            author
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
